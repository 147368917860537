.home {
  /* background-image: url("../../assets/images/screens.png");
  background-size: cover;
  background-repeat: no-repeat; */
}
section {
  position: relative;
}
.video__wrapper {
  z-index: -1;
  position: absolute;
  inset: 0;
  min-height: 100vh;
  width: 100%;
  background-color: #989898;
}

.video__wrapper video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  /* mix-blend-mode: multiply; */
}
