.assistant {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 10px;
  overflow: hidden;
  justify-content: right;
  transition: 0.3s ease-in-out all;
}
.assistant p {
  height: 65px;
  width: 90%;
  margin: 0;
}

.assistant.expanded {
  height: 170px;
  width: 360px;
  border-radius: 10px 10px 40px 10px;
}

.assistant__inner {
  height: 150px;
  width: 340px;
  border-radius: 10px 10px 40px 10px;
  position: absolute;
  right: 10px;
  bottom: 5px;
}

@media screen and (max-width: 768px) {
  .assistant {
    display: none;
  }
}
