.usernft__outer {
  margin-top: 16px;
  height: 69%;
  /* padding: 10px; */
  overflow-y: auto;
  /* border: 1px solid #00000082; */
}
.usernft__container {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.usernft__item {
  width: 380px;
  height: 200px;
  display: flex;
  /* align-items: center; */
  /* flex-grow: 1; */
}

@media screen and (max-width: 456px) {
  .usernft__item {
    width: 100%;
    height: auto;
  }
}

.usernft__image__item {
  position: relative;
  cursor: pointer;
}
/* .usernft__image__item::before {
  inset: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  content: "View NFT";
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.236);
  transition: 0.2s ease-in-out opacity;
  opacity: 0;
  z-index: 2;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  font-family: "gt, sans-serif";
} */

.usernft__image__item:hover::before {
  opacity: 1;
}
