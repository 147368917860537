@font-face {
  font-family: "gt";
  src: url("./assets/fonts/gtregular.woff2");
  font-weight: 400;
}
@font-face {
  font-family: "gt";
  src: url("./assets/fonts/gtmedium.woff2");
  font-weight: 500;
}
@font-face {
  font-family: "gt";
  src: url("./assets/fonts/gtbold.woff2");
  font-weight: 600;
}
@font-face {
  font-family: "gt";
  src: url("./assets/fonts/gtblack.woff2");
  font-weight: 800;
}
@font-face {
  font-family: "gt";
  src: url("./assets/fonts/gtextra.woff2");
  font-weight: 900;
}

body {
  color: #1f1f1f;
}

h1,
h2,
h3,
h4,
.gt-w {
  font-family: "gt", sans-serif;
}
.App {
  position: relative;
}
.App,
.fade-in {
  animation: 0.4s ease-in fade-in;
}

.navigation {
  animation: fade-in 0.3s ease-in-out;
  overflow: hidden;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navigation__panel {
  animation: slide-in 0.3s ease-in-out;
}

@keyframes slide-in {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0%);
  }
}

.content__block {
  background: linear-gradient(
      236.12deg,
      #f7fdfd7c 1.05%,
      #cbfff9 55.75%,
      #f7fdfd74 87.85%
    ),
    linear-gradient(
      252.01deg,
      #f7fdfd5e 3.16%,
      rgba(203, 255, 249, 0.5) 50.15%,
      rgba(247, 253, 253, 0.456) 90.76%
    ),
    conic-gradient(
      from 150.05deg at 46.2% 47.53%,
      #ffffff72 0deg,
      #ffffff41 121.87deg,
      rgba(255, 255, 255, 0) 258.75deg,
      #ffffff70 360deg
    );

  backdrop-filter: blur(5px);
  border: 1px solid white;
}

.vectorGrad,
.lightvector,
.vector3,
.vector4,
.vector5 {
  background-image: url("./assets/images/vectorGrad.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200% 100%;
  min-height: 100vh;
  animation: gradientTransform linear infinite 3s;
}
.lightvector,
.vector3x {
  background-image: url("./assets/images/lightVector.png");
}
.vector3 {
  background-image: url("./assets/images/verctor3.png");
  animation: gradientTransform linear infinite 5s;
}

.vector3x {
  height: 50vh;
  animation: none;
}
.vector4 {
  background-image: url("./assets/images/vector4.png");
}
.vector5 {
  background-image: url("./assets/images/vector5.png");
}

*::-webkit-scrollbar {
  background-color: rgba(204, 204, 204, 0);
  border-radius: 8px;
  width: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 8px;
}
.line {
  position: absolute;
  width: 3px;
  height: 20px;
  left: 11px;
  top: 30px;
  z-index: 0;
}

/* Toggle A */
.toggle:checked ~ .dot {
  transform: translateX(100%);
  background-color: #3bbeae;
}

.frameouter {
  margin: auto;
  padding: 10px;
  display: grid;
  place-items: center;
  background-color: #d3d3d395;
}
.frameinner {
  background-image: url("./assets/images/emptyFrame.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;

  inset: 0;
  z-index: 2;
}
.image__container__signed {
  background-image: url("./assets/images/lightVector.png");
  background-position: left top;
  background-size: 200%;
}

.gradp2 {
  background-image: url("./assets/images/lightVector.png");
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
}

.box-shadow-block {
  box-shadow: -45px 311px 126px rgba(0, 0, 0, 0.01),
    -26px 175px 106px rgba(0, 0, 0, 0.05), -11px 78px 79px rgba(0, 0, 0, 0.09),
    -3px 19px 43px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.mockup__container {
  background-image: url("./assets/images/iphone12mockup.svg");
  background-size: contain;
  width: 100%;
  margin: auto;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 3px 2px #000;
}

.unlockable__item .tooltip {
  transform: scale(0);
  transition: 0.2s ease-in-out transform;
  z-index: 15;
}

.unlockable__item:hover .tooltip {
  transform: scale(1);
}

.copytext:hover {
  /* all: unset; */
  position: relative;
  cursor: pointer;
  background-color: transparent !important;
  color: #000 !important;
}

.copytext:before {
  content: "";
  display: none;
  position: absolute;
  z-index: 10;
  top: 25px;
  /* left: 35px; */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(0, 0, 0, 0.72);
}
.copytext:after {
  content: "Copy to Clipboard";
  display: none;
  position: absolute;
  z-index: 120;
  top: 30px;
  left: -0px;
  width: 114px;
  height: 36px;
  color: #fff;
  font-size: 10px;
  line-height: 36px;
  text-align: center;
  background: rgba(0, 0, 0, 0.72);
  border-radius: 3px;
}
.copytext.files::before {
  left: 15px;
}
.copytext.files::after {
  height: 70px;
  line-height: 1.5;
  padding: 5px;
  left: -80px;
}
.copytext.files.assets::after {
  content: "JPG, PNG, JPEG, GIF, SVG, MP4, WEBM, WEBP, MP3, WAV, OGG, GLTF, GLB";
}
.copytext.files.preview::after {
  content: "PNG, JPG, JPEG, WEBP";
  height: 40px;
}
.copytext.files.metadata::after {
  content: "JSON, CSV";
  height: 24px;
}
.copytext.files:hover {
  background-color: transparent;
}
.copytext:hover {
  background-color: #eee;
}
.copytext:hover:before,
.copytext:hover:after {
  display: block;
}
.copytext:active,
.copytext:focus {
  outline: none;
}
.copytext.files:active:after,
.copytext.files:focus:after {
  content: initial;
}
.copytext:active:after,
.copytext:focus:after {
  content: "Copied!";
}

.buttons.btn-rainbow button {
  width: 100%;
  border-radius: 9998px !important;
  padding: 16px 20px !important;
  text-align: center !important;
  height: auto !important;
}

.buttonInNav button > div {
  all: unset;
  width: 100%;
  display: flex;
  height: 100% !important;
}
.buttonInNav button > div > div {
  all: unset;
  display: flex !important;
  align-items: center;
  justify-content: space-around;
  width: 100% !important;
}

.buttonInNav button div > div div {
  margin: 0 !important;
  padding: 0 !important;
}

model-viewer {
  height: 360px;
  width: 360px;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
  background-color: #fff;
  margin: auto;
  border-radius: 16px;
  /* className="h-[300px] w-[300px] md:h-[200px] md:w-[200px] lg:h-[360px] lg:w-[360px]  object-contain object-center mx-auto rounded-xl border-4 border-solid border-white" */
}

@media screen and (max-width: 991px) {
  model-viewer {
    height: 200px;
    width: 200px;
  }
}

@media screen and (max-width: 768px) {
  model-viewer {
    height: 300px;
    width: 300px;
  }
}

button {
  font-size: 20px;

  transition: 0.2s ease-in-out background-color;
}
button:hover:enabled {
  /* filter: saturate(66%);
   */
  background-color: #008376;
  color: #fff;
}
@media screen and (max-width: 768px) {
  button {
    font-size: 18px;
  }
}

button:disabled {
  opacity: 0.8;
}

.extended {
  animation: autoHeight 0.2s linear;
  overflow: hidden;
}
.extended2 {
  animation: autoHeight2 0.2s linear;
  overflow: hidden;
}

.not-extended {
  animation: noHeight 0.2s linear;
  overflow: hidden;
  height: 0px;
}

@keyframes autoHeight {
  0% {
    height: 0;
  }
  100% {
    height: 160px;
  }
}
@keyframes autoHeight2 {
  0% {
    height: 0;
  }
  100% {
    height: 120px;
  }
}
@keyframes noHeight {
  0% {
    height: 160px;
  }
  100% {
    height: 0px;
  }
}

@keyframes gradientTransform {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 50% 40%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 50% 40%;
  }
  100% {
    background-position: 0 0;
  }
}
